<template>
  <div>
    <validation-observer ref="observer" v-slot="{ invalid }">
      <form @submit.prevent="form">
        <div class="row">
          <div class="col-12 pt-5">
            <h6>
              <v-icon class="pb-1" v-text="'mdi-numeric-1-box-outline'" />
              {{ $t("employee.personal_information") }}
            </h6>
          </div>
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              name="name"
              rules="required|max:45|alpha_spaces"
            >
              <v-text-field
                v-model="model.name"
                :counter="100"
                :error-messages="errors"
                oninput="this.value = this.value.toUpperCase()"
                :label="$t('label.name')"
                required
              />
            </validation-provider>
          </div>
          <div class="col-md-8">
            <validation-provider
              v-slot="{ errors }"
              name="lastname"
              rules="required|max:45|alpha_spaces"
            >
              <v-text-field
                v-model="model.lastName"
                :counter="200"
                oninput="this.value = this.value.toUpperCase()"
                :error-messages="errors"
                :label="$t('label.lastname')"
                required
              />
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              name="phone"
              rules="required|max:20|numeric"
            >
              <v-text-field
                type="text"
                maxlength="20"
                v-model="model.phone"
                :counter="20"
                :error-messages="errors"
                :label="$t('label.phone')"
                required
              />
            </validation-provider>
          </div>
          <div class="col-md-4">
            <vc-date-picker
              color="green"
              :max-date="new Date()"
              v-model="model.dateOfBirth"
              is-required
              :update-on-input="false"
              popover-align="center"
              :model-config="modelConfig"
              :locale="lang"
            >
              <template v-slot="{ inputEvents }">
                <div class="flex justify-center items-center">
                  <validation-provider
                    v-slot="{ errors }"
                    name="birth_date"
                    rules="required"
                  >
                    <v-text-field
                      v-on="inputEvents"
                      :error-messages="errors"
                      :value="model.dateOfBirth"
                      :readonly="true"
                      :label="$t('label.birth_date')"
                      required
                      :disabled-input="dataValidation"
                    ></v-text-field>
                  </validation-provider>
                </div>
              </template>
            </vc-date-picker>
          </div>
          <div class="col-md-4">
            <validation-provider
              v-slot="{ errors }"
              name="gender"
              rules="required"
            >
              <v-autocomplete
                :error-messages="errors"
                no-data-text="Sin resultados"
                :items="genderList"
                item-value="value"
                auto-select-first
                v-model="model.gender"
                :label="$t('label.gender')"
              >
                <template v-slot:selection="data">
                  {{ $t(data.item.value) }}
                </template>
                <template v-slot:item="data">
                  {{ $t(data.item.value) }}
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pt-5">
            <h6>
              <v-icon class="pb-1" v-text="'mdi-numeric-2-box-outline'" />
              {{ $t("employee.additional_information") }}
            </h6>
          </div>
          <div class="col-md-6">
            <validation-provider v-slot="{ errors }" name="manager">
              <v-autocomplete
                item-text="fullName"
                item-value="idEmployee"
                clearable
                :items="employeesList"
                :no-data-text="$t('label.no_results')"
                v-model="model.employeeParent.idEmployee"
                :error-messages="errors"
                :label="$t(labelEmployee)"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.idEmployee }} | {{ data.item.fullName }}
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.idEmployee }} | {{ data.item.fullName }}
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
          <div class="col-md-6">
            <validation-provider
              v-slot="{ errors }"
              name="charge"
              rules="required"
            >
              <v-autocomplete
                item-text="name"
                item-value="idPosition"
                :items="positions"
                :no-data-text="$t('label.no_results')"
                v-model="model.position.idPosition"
                :error-messages="errors"
                :label="$t('label.charge')"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
          <div class="col-md-12">
            <validation-provider
              v-slot="{ errors }"
              name="department"
              rules="required"
            >
              <v-autocomplete
                item-text="name"
                item-value="idDepartment"
                :items="departments"
                :no-data-text="$t('label.no_results')"
                v-model="model.department.idDepartment"
                :error-messages="errors"
                :label="$t('label.department')"
              >
                <template slot="item" slot-scope="data">
                  {{ data.item.name }}
                </template>
                <template slot="selection" slot-scope="data">
                  {{ data.item.name }}
                </template>
              </v-autocomplete>
            </validation-provider>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 text-right">
            <button
              v-if="model.idEmployee != ''"
              class="btn btn-outline-success"
              type="submit"
              :disabled="invalid || sending"
            >
              <i class="feather icon-save mr-1"></i>{{ $t("global.modify_employee") }}
            </button>
            <button
              v-else
              class="btn btn-outline-success"
              type="submit"
              :disabled="invalid || sending"
            >
              <i class="feather icon-save mr-1"></i> {{ $t("global.save_employee") }}
            </button>
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import { mapState } from "vuex";
import genderData from "@/assets/json/gender.json";
import { UtilFront } from "@/core";
import { EmployeeRequest } from "@/core/request";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  props: {
    employees: {
      required: true,
      default: [],
    },
    departments: {
      required: true,
      default: [],
    },
    positions: {
      required: true,
      default: [],
    },
    idBranchOffice: {
      required: true,
      default: 0,
    },
  },
  data() {
    return {
      model: {
        idEmployee: "",
        dateOfBirth: "",
        name: "",
        lastName: "",
        gender: "",
        phone: "",
        employeeParent: {
          idEmployee: null,
        },
        position: {
          idPosition: "",
        },
        department: {
          idDepartment: "",
        },
      },
      sending: false,

      genderList: null,
      /* Configuración de fechas */
      modelConfig: {
        type: "string",
        mask: "DD/MM/YYYY",
      },
    };
  },
  methods: {
    reloadComponentUpdate(item) {
      item.employeeParent == "" ||
      item.employeeParent == null ||
      item.employeeParent == undefined
        ? (this.model = {
            ...item,
            employeeParent: {
              idEmployee: null,
            },
          })
        : (this.model = item);
    },
    form() {
      this.model.idEmployee != "" ? this.updateEmployee() : this.addEmployee();
    },
    updateEmployee() {
      if (!this.sending) {
        this.loading = UtilFront.getLoadding();
        this.sending = true;
        this.model = {
          ...this.model,
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.idBranchOffice,
        };
        EmployeeRequest.updateEmployee(this.model)
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.$emit("changeAction", 1);
              this.$emit("alert", "success", "Update_Success_0001");
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit("alert", "internal");
          })
          .finally(() => {
            this.sending = false;
            this.loading.hide();
          });
      }
    },
    addEmployee() {
      if (!this.sending) {
        this.loading = UtilFront.getLoadding();
        this.sending = true;
        this.model = {
          ...this.model,
          idUser: UtilFront.getDataUser().idUser,
          idBranchOffice: this.idBranchOffice,
        };
        EmployeeRequest.addEmployee(this.model)
          .then((response) => {
            let { success, message } = response.data.data;
            if (success) {
              this.$emit("changeAction", 1);
              this.$emit("alert", "success", "Add_Success_0001");
            } else {
              this.$emit("alert", "error", message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.$emit("alert", "internal");
          })
          .finally(() => {
            this.sending = false;
            this.loading.hide();
          });
      }
    },
    cancel() {
      this.model.name = this.model.idEmployee = this.model.employeeParent.idEmployee = this.model.lastName = this.model.phone = this.model.department.idDepartment = this.model.position.idPosition = this.model.gender = this.model.dateOfBirth =
        "";
      this.$refs.observer.reset();
    },
  },
  computed: {
    ...mapState("locales", ["lang"]),
    dataValidation() {
      if (
        this.birthday == "" ||
        this.birthday == null ||
        this.birthday == undefined
      ) {
        return false;
      }
      return true;
    },
    employeesList() {
      return this.employees.filter(
        (employee) => employee.idEmployee !== this.model.idEmployee
      );
    },
    labelEmployee() {
      return this.model.employeeParent.idEmployee == "" ||
        this.model.employeeParent.idEmployee == null ||
        this.model.employeeParent.idEmployee == undefined
        ? "label.no_employee_charge"
        : "label.employee_charge";
    },
  },
  created() {
    this.genderList = genderData;
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
};
</script>
