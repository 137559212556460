<template>
  <!-- Start Containerbar -->
  <div id="containerbar">
    <!-- Start Rightbar -->
    <div class="rightbar">
      <!-- Start Breadcrumbbar -->
      <div class="breadcrumbbar">
        <div class="row align-items-center">
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12">
            <h4 class="page-title">{{ $t("employee.supplies") }}</h4>
            <div class="breadcrumb-list">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="/dashboard">{{ $t("employee.home") }}</a>
                </li>
                <li class="breadcrumb-item">
                  <a @click="changeAction(1)">{{
                    $t("employee.administration")
                  }}</a>
                </li>
                <li class="breadcrumb-item">{{ $t(title) }}</li>
              </ol>
            </div>
          </div>
          <div class="col-md-6 col-lg-6 col-xs-12 col-sm-12 content-button">
            <button
              class="btn btn-outline-success"
              v-if="
                withPermissionName(permissions.DIS_STA_ADD_01) && showAction(1)
              "
              @click="changeAction(2)"
            >
              <i class="ti-plus"></i> {{ $t("global.add_employee") }}
            </button>
            <button
              class="btn btn-outline-success"
              v-if="showAction(2) || showAction(3)"
              @click="changeAction(1)"
            >
              <i class="ti-arrow-left mr-2"></i> {{ $t("global.return_to") }}
            </button>
          </div>
        </div>
      </div>

      <div class="contentbar">
        <div class="row">
          <div class="col-lg-12 col-xl-12">
            <div class="card">
              <AlertMessageComponent ref="alertMessageComponent" />
              <div class="card-header">
                <div class="row align-items-center">
                  <div class="col">
                    <h5 class="card-title">
                      <i class="feather icon-user-check mr-2" aria-hidden="true"></i>
                      {{ $t(title) }}
                    </h5>
                  </div>
                </div>
              </div>
              <EmployeeTableComponent
                v-on:excelTable="excelTable"
                :isUpdate="withPermissionName(permissions.DIS_STA_EDIT_02)"
                v-if="withPermissionName(permissions.STAFF)"
                v-show="showAction(1)"
                :dataList="dataList"
                :headers="employeeHeaders"
                @updateItem="edit"
              />
              <div class="card-body" v-show="showAction(2) || showAction(3)">
                <AddEmployeeComponent
                  ref="formEmployee"
                  @changeAction="changeAction"
                  @alert="alert"
                  :idBranchOffice="originId"
                  :employees="employees"
                  :departments="departments"
                  :positions="positions"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SelectBranchOffice from "@/common/select/SelectBranchOffice.vue";
import { EmployeeRequest, ExcelRequest } from "@/core/request";
import { UtilFront, PermissionConstants } from "@/core";
import EmployeeTableComponent from "@/components/employee/EmployeeTableComponent";
import AddEmployeeComponent from "@/components/employee/AddEmployeeComponent";
import AlertMessageComponent from "@/common/message/AlertMessageComponent";
import { mapState, mapMutations } from "vuex";
import { EMPLOYEE } from "@/core/DataTableHeadersLocale";

export default {
  data() {
    return {
      /* Table */
      employeeHeaders: EMPLOYEE(),
      action: 1,
      positions: [],
      employees: [],
      departments: [],
      dataList: [],
      originId: "",
      loading: null,
      /* Permisos */
      permissions: PermissionConstants,
    };
  },
  computed: {
    ...mapState("auth", {
      branchOfficeState: "branchOffice",
    }),
    title() {
      return this.action == 1
        ? "employee.employee"
        : this.action == 2
        ? "employee.register_employee"
        : "employee.modify_employee";
    },
  },
  methods: {
    // Funcion para el cambio del actionState
    ...mapMutations("auth", {
      updateAction: "UPDATE_ACTION",
    }),
    withPermissionName(numRol) {
      return UtilFront.withPermissionName(numRol);
    },
    alert(type, message) {
      this.$refs.alertMessageComponent.reloadComponent(type, message);
    },
    updateOrigin(data) {
      this.originId = data;
      this.listAllEmployees();
    },
    edit(item) {
      this.changeAction(3);
      this.$refs.formEmployee.reloadComponentUpdate(item);
    },
    showAction(action) {
      return this.action == action;
    },
    changeAction(action) {
      this.action = action;
      action == 1
        ? this.listAllEmployees()
        : this.action == 2
        ? this.reload()
        : null;
      this.updateAction(action);
    },
    listAllEmployees() {
      this.loading = UtilFront.getLoadding();
      EmployeeRequest.listAllEmployees({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
      })
        .then((response) => {
          let { success, data } = response.data.data;
          if (success) {
            this.dataList = this.employees = data;
          } else {
            this.dataList = this.employees = [];
            //this.$toast.error(message);
          }
        })
        .catch((error) => {
          this.alert("error", error);
        })
        .finally(() => {
          this.loading.hide();
        });
    },
    listPosition() {
      EmployeeRequest.listAllPosition({
        idUser: UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { success, data, message } = response.data.data;
          success ? (this.positions = data) : this.$toast.error(message);
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    listDepartment() {
      EmployeeRequest.listAllDepartment({
        idUser: UtilFront.getDataUser().idUser,
      })
        .then((response) => {
          let { success, message, data } = response.data.data;
          success ? (this.departments = data) : this.$toast.error(message);
        })
        .catch((error) => {
          this.$toast.error(error);
        })
        .finally(() => {});
    },
    reload() {
      this.$refs.formEmployee.cancel();
    },
    excelTable() {
      this.$toast.success("Generando documento Xlsx");
      ExcelRequest.reportEmployees({
        idUser: UtilFront.getDataUser().idUser,
        idBranchOffice: this.originId,
      });
    },
  },
  created() {
    /*  Asigan el valor del state para sucursal global */
    this.updateOrigin(this.branchOfficeState.idBranchoffice);
    this.updateAction(1);
    this.listPosition();
    this.listDepartment();
  },
  watch: {
    branchOfficeState() {
      this.updateOrigin(this.branchOfficeState.idBranchoffice);
    },
    "$i18n.locale": function () {
      this.employeeHeaders = EMPLOYEE();
    },
  },
  components: {
    EmployeeTableComponent,
    AddEmployeeComponent,
    SelectBranchOffice,
    AlertMessageComponent,
  },
};
</script>
