var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.form.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-5"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-1-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("employee.personal_information"))+" ")],1)]),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"name","rules":"required|max:45|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":100,"error-messages":errors,"oninput":"this.value = this.value.toUpperCase()","label":_vm.$t('label.name'),"required":""},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-8"},[_c('validation-provider',{attrs:{"name":"lastname","rules":"required|max:45|alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"counter":200,"oninput":"this.value = this.value.toUpperCase()","error-messages":errors,"label":_vm.$t('label.lastname'),"required":""},model:{value:(_vm.model.lastName),callback:function ($$v) {_vm.$set(_vm.model, "lastName", $$v)},expression:"model.lastName"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"phone","rules":"required|max:20|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","maxlength":"20","counter":20,"error-messages":errors,"label":_vm.$t('label.phone'),"required":""},model:{value:(_vm.model.phone),callback:function ($$v) {_vm.$set(_vm.model, "phone", $$v)},expression:"model.phone"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-4"},[_c('vc-date-picker',{attrs:{"color":"green","max-date":new Date(),"is-required":"","update-on-input":false,"popover-align":"center","model-config":_vm.modelConfig,"locale":_vm.lang},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputEvents = ref.inputEvents;
return [_c('div',{staticClass:"flex justify-center items-center"},[_c('validation-provider',{attrs:{"name":"birth_date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g({attrs:{"error-messages":errors,"value":_vm.model.dateOfBirth,"readonly":true,"label":_vm.$t('label.birth_date'),"required":"","disabled-input":_vm.dataValidation}},inputEvents))]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.model.dateOfBirth),callback:function ($$v) {_vm.$set(_vm.model, "dateOfBirth", $$v)},expression:"model.dateOfBirth"}})],1),_c('div',{staticClass:"col-md-4"},[_c('validation-provider',{attrs:{"name":"gender","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"error-messages":errors,"no-data-text":"Sin resultados","items":_vm.genderList,"item-value":"value","auto-select-first":"","label":_vm.$t('label.gender')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.value))+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(_vm.$t(data.item.value))+" ")]}}],null,true),model:{value:(_vm.model.gender),callback:function ($$v) {_vm.$set(_vm.model, "gender", $$v)},expression:"model.gender"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 pt-5"},[_c('h6',[_c('v-icon',{staticClass:"pb-1",domProps:{"textContent":_vm._s('mdi-numeric-2-box-outline')}}),_vm._v(" "+_vm._s(_vm.$t("employee.additional_information"))+" ")],1)]),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"manager"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-text":"fullName","item-value":"idEmployee","clearable":"","items":_vm.employeesList,"no-data-text":_vm.$t('label.no_results'),"error-messages":errors,"label":_vm.$t(_vm.labelEmployee)},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.idEmployee)+" | "+_vm._s(data.item.fullName)+" ")]}},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.idEmployee)+" | "+_vm._s(data.item.fullName)+" ")]}}],null,true),model:{value:(_vm.model.employeeParent.idEmployee),callback:function ($$v) {_vm.$set(_vm.model.employeeParent, "idEmployee", $$v)},expression:"model.employeeParent.idEmployee"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"charge","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"idPosition","items":_vm.positions,"no-data-text":_vm.$t('label.no_results'),"error-messages":errors,"label":_vm.$t('label.charge')},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}}],null,true),model:{value:(_vm.model.position.idPosition),callback:function ($$v) {_vm.$set(_vm.model.position, "idPosition", $$v)},expression:"model.position.idPosition"}})]}}],null,true)})],1),_c('div',{staticClass:"col-md-12"},[_c('validation-provider',{attrs:{"name":"department","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-text":"name","item-value":"idDepartment","items":_vm.departments,"no-data-text":_vm.$t('label.no_results'),"error-messages":errors,"label":_vm.$t('label.department')},scopedSlots:_vm._u([{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}},{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.name)+" ")]}}],null,true),model:{value:(_vm.model.department.idDepartment),callback:function ($$v) {_vm.$set(_vm.model.department, "idDepartment", $$v)},expression:"model.department.idDepartment"}})]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 text-right"},[(_vm.model.idEmployee != '')?_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid || _vm.sending}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(_vm._s(_vm.$t("global.modify_employee"))+" ")]):_c('button',{staticClass:"btn btn-outline-success",attrs:{"type":"submit","disabled":invalid || _vm.sending}},[_c('i',{staticClass:"feather icon-save mr-1"}),_vm._v(" "+_vm._s(_vm.$t("global.save_employee"))+" ")])])])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }